exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-archive-jsx": () => import("./../../../src/templates/archive.jsx" /* webpackChunkName: "component---src-templates-archive-jsx" */),
  "component---src-templates-archive-list-jsx": () => import("./../../../src/templates/archive-list.jsx" /* webpackChunkName: "component---src-templates-archive-list-jsx" */),
  "component---src-templates-areas-of-work-jsx": () => import("./../../../src/templates/areas-of-work.jsx" /* webpackChunkName: "component---src-templates-areas-of-work-jsx" */),
  "component---src-templates-coverage-jsx": () => import("./../../../src/templates/coverage.jsx" /* webpackChunkName: "component---src-templates-coverage-jsx" */),
  "component---src-templates-coverage-list-jsx": () => import("./../../../src/templates/coverage-list.jsx" /* webpackChunkName: "component---src-templates-coverage-list-jsx" */),
  "component---src-templates-how-money-works-jsx": () => import("./../../../src/templates/how-money-works.jsx" /* webpackChunkName: "component---src-templates-how-money-works-jsx" */),
  "component---src-templates-landing-jsx": () => import("./../../../src/templates/landing.jsx" /* webpackChunkName: "component---src-templates-landing-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-policy-resources-list-jsx": () => import("./../../../src/templates/policy-resources-list.jsx" /* webpackChunkName: "component---src-templates-policy-resources-list-jsx" */),
  "component---src-templates-press-release-jsx": () => import("./../../../src/templates/press-release.jsx" /* webpackChunkName: "component---src-templates-press-release-jsx" */),
  "component---src-templates-press-release-list-jsx": () => import("./../../../src/templates/press-release-list.jsx" /* webpackChunkName: "component---src-templates-press-release-list-jsx" */),
  "component---src-templates-publication-jsx": () => import("./../../../src/templates/publication.jsx" /* webpackChunkName: "component---src-templates-publication-jsx" */),
  "component---src-templates-resources-list-jsx": () => import("./../../../src/templates/resources-list.jsx" /* webpackChunkName: "component---src-templates-resources-list-jsx" */),
  "component---src-templates-take-action-jsx": () => import("./../../../src/templates/take-action.jsx" /* webpackChunkName: "component---src-templates-take-action-jsx" */),
  "component---src-templates-team-jsx": () => import("./../../../src/templates/team.jsx" /* webpackChunkName: "component---src-templates-team-jsx" */),
  "component---src-templates-update-jsx": () => import("./../../../src/templates/update.jsx" /* webpackChunkName: "component---src-templates-update-jsx" */),
  "component---src-templates-update-list-jsx": () => import("./../../../src/templates/update-list.jsx" /* webpackChunkName: "component---src-templates-update-list-jsx" */),
  "component---src-templates-vacancy-jsx": () => import("./../../../src/templates/vacancy.jsx" /* webpackChunkName: "component---src-templates-vacancy-jsx" */),
  "component---src-templates-video-jsx": () => import("./../../../src/templates/video.jsx" /* webpackChunkName: "component---src-templates-video-jsx" */),
  "component---src-templates-video-list-jsx": () => import("./../../../src/templates/video-list.jsx" /* webpackChunkName: "component---src-templates-video-list-jsx" */),
  "component---src-templates-work-jsx": () => import("./../../../src/templates/work.jsx" /* webpackChunkName: "component---src-templates-work-jsx" */)
}

